import { FC, PropsWithChildren } from 'react';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import { Capacitor } from '@capacitor/core';
import { CapacitorStorage } from './CapacitorStorage';
import { CapacitorNavigator } from './CapacitorNavigator';
import { BpUserManager } from './BpUserManager';
import { Browser } from '@capacitor/browser';
export const bpUserManager = new BpUserManager();
export const capacitorStorage = new CapacitorStorage();
const capacitorNavigator = new CapacitorNavigator();

export const BpAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const isNative = Capacitor.isNativePlatform();

  const manager = isNative
    ? bpUserManager.setUserManager(capacitorStorage, capacitorNavigator)
    : bpUserManager.setUserManager(window.sessionStorage);
  const oidcConfig: AuthProviderProps = {
    userManager: manager,
    onSigninCallback: (_user: User | void): void => {
      window.history.replaceState({}, document.title, window.location.pathname);
      if (isNative) {
        Browser.close();
      }
    },
  };

  return (
    <>
      <AuthProvider {...oidcConfig}>{children}</AuthProvider>
    </>
  );
};
