import { NotificationReason, NotificationType } from '../../../../utils/matrixClient';
import { t } from 'i18next';

export function mapNotification(notification: NotificationType) {
  let content = '';
  let targetUrl = '#';

  // can be removed when typing ist consistent
  const type = notification.type?.charAt(0).toLowerCase() + notification.type?.slice(1);

  switch (type) {
    case NotificationReason.GroupEventStarted:
      content = `${t('notifications.eventStarted')} <strong>${notification.groupName}</strong>: ${notification.subjectName}`;
      targetUrl = `/groups/${notification.groupUuid}/`;
      break;

    case NotificationReason.CourseEventStarted:
      content = `${t('notifications.eventStarted')} <strong>${notification.groupName}</strong>: ${notification.subjectName}`;
      targetUrl = `/courses/${notification.groupUuid}/`;
      break;

    case NotificationReason.OrganizationEventStarted:
      content = `${t('notifications.eventStarted')}: ${notification.subjectName}`;
      targetUrl = `/`;
      break;

    case NotificationReason.GroupEventEnded:
      content = `${t('notifications.eventEnded')} <strong>${notification.groupName}</strong>: ${notification.subjectName}`;
      targetUrl = `/groups/${notification.groupUuid}/`;
      break;

    case NotificationReason.CourseEventEnded:
      content = `${t('notifications.eventEnded')} <strong>${notification.groupName}</strong>: ${notification.subjectName}`;
      targetUrl = `/courses/${notification.groupUuid}/`;
      break;

    case NotificationReason.OrganizationEventEnded:
      content = `${t('notifications.eventEnded')}: ${notification.subjectName}`;
      targetUrl = `/`;
      break;

    case NotificationReason.GroupEventUpdated:
      content = `${t('notifications.eventUpdated')} <strong>${notification.groupName}</strong>: ${notification.subjectName}`;
      targetUrl = `/groups/${notification.groupUuid}/`;
      break;

    case NotificationReason.CourseEventUpdated:
      content = `${t('notifications.eventUpdated')} <strong>${notification.groupName}</strong>: ${notification.subjectName}`;
      targetUrl = `/courses/${notification.groupUuid}/`;
      break;

    case NotificationReason.OrganizationEventUpdated:
      content = `${t('notifications.eventUpdated')} <strong>${notification.title}</strong>: ${notification.subjectName}`;
      targetUrl = `/`;
      break;

    case NotificationReason.CourseEventCreated:
      content = `${t('notifications.newCourseEvent')} <strong>${notification.groupName}</strong>: ${notification.subjectName}`;
      targetUrl = `/courses/${notification.groupUuid}/appointments`;
      break;

    case NotificationReason.RecurringCourseEventCreated:
      content = `${t('notifications.newRecurringCourseEvent')} <strong>${notification.groupName}</strong>: ${notification.subjectName}`;
      targetUrl = `/groups/${notification.groupUuid}/appointments`;
      break;

    case NotificationReason.RecurringEventCreated:
      content = `${t('notifications.newRecurringEvent')}  <strong>${notification.title}</strong>: ${notification.subjectName}`;
      targetUrl = `/`;
      break;

    case NotificationReason.GroupEventCreated:
      content = `${t('notifications.newGroupEvent')} <strong>${notification.groupName}</strong>: ${notification.subjectName}`;
      targetUrl = `/groups/${notification.groupUuid}/appointments`;
      break;

    case NotificationReason.RecurringGroupEventCreated:
      content = `${t('notifications.newRecurringGroupEvent')} <strong>${notification.groupName}</strong>: ${notification.subjectName}`;
      targetUrl = `/groups/${notification.groupUuid}/appointments`;
      break;

    case NotificationReason.OrganizationEventCreated:
      content = `${t('notifications.newOrganizationEvent')} <strong>${notification.title}</strong>: ${notification.subjectName}`;
      targetUrl = `/`;
      break;

    case NotificationReason.AddedToGroup:
      if (notification.isCommunity) {
        // collaboration group
        content = `${t('notifications.addedToCollabGroup')} <strong>${notification.groupName}</strong>`;
      } else {
        // normal group
        content = `${t('notifications.addedToGroup')} <strong>${notification.groupName}</strong>`;
      }
      if (notification.groupUuid) targetUrl = `/groups/${notification.groupUuid}`;
      break;

    case NotificationReason.AddedToCourse:
      content = `${t('notifications.newCourse')} <strong>${notification.groupName}</strong>`;
      if (notification.groupUuid) targetUrl = `/courses/${notification.groupUuid}`;
      break;

    case NotificationReason.RemovedFromGroup:
      if (notification.isCommunity) {
        // collaboration group
        content = `${t('notifications.removedFromCollabGroup')} <strong>${notification.groupName}</strong>`;
      } else {
        // normal group
        content = `${t('notifications.removedFromGroup')} <strong>${notification.groupName}</strong>`;
      }
      break;

    case NotificationReason.RemovedFromCourse:
      content = `${t('notifications.removedFromCourse')} <strong>${notification.groupName}</strong>`;
      break;

    case NotificationReason.GroupAnnouncement:
      content = `${t('notifications.announcementInGroup')} <strong>${notification.groupName}</strong>: ${
        notification.title
      }`;
      if (notification.groupUuid) targetUrl = `/groups/${notification.groupUuid}`;
      break;

    case NotificationReason.OrganizationAnnouncement:
      // other organization
      if (notification.organizationUuid !== notification.subjectUuid) {
        content = `${t('notifications.announcementInOrganization')}  <strong>${notification.subjectName}</strong>: ${
          notification.title
        } - ${t('notifications.changeOrganization')}`;
      }

      if (notification.organizationUuid === notification.subjectUuid) {
        content = `${t('notifications.announcementInOrganization')}  <strong>${notification.subjectName}</strong>: ${
          notification.title
        }`;

        targetUrl = `/`;
      }

      break;

    case NotificationReason.CourseAnnouncement:
      content = `${t('notifications.announcementInCourse')} <strong>${notification.subjectName}</strong>: ${
        notification.title
      }`;
      targetUrl = `/courses/${notification.groupUuid}`;
      break;

    case NotificationReason.AssignmentOpened:
      content = t('notifications.newAssignment', { name: notification.subjectName });
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.subjectUuid}`;
      break;

    case NotificationReason.AssignmentUpdated:
      content = t('notifications.assignmentUpdated', { name: notification.subjectName });
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.subjectUuid}`;
      break;

    case NotificationReason.AssignmentDeleted:
      content = t('notifications.assignmentDeleted', { name: notification.subjectName });
      targetUrl = `/courses/${notification.groupUuid}/assignments`;
      break;

    case NotificationReason.Comment:
      content = `${t('groups.newMessageHint')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/messages`;
      break;

    case NotificationReason.SubmissionDePublished:
      content = `${t('notifications.publicSubmissionRejected')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;

    case NotificationReason.RequestToFeedback:
      content = `${t('notifications.feedbackRequested')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;
    case NotificationReason.RequestToPublish:
      content = `${t('notifications.submissionPublicationRequested')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;

    case NotificationReason.FeedbackRejected:
      content = `${t('notifications.feedbackRejected')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;

    case NotificationReason.SubmissionFeedback:
      content = `${t('notifications.feedbackGranted')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;

    case NotificationReason.SubmissionUpdated:
      content = `${t('notifications.submissionEdited')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;

    case NotificationReason.SubmissionMessage:
      content = `${t('notifications.messageInCourse', { name: notification.subjectName })}`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;

    case NotificationReason.CourseMaterialCreated:
      content = `${t('notifications.newCourseMaterial')} <strong>${notification.groupName} - ${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/workmaterials`;

      break;

    case NotificationReason.CourseMaterialUpdated:
      content = `${t('notifications.materialUpdated')} <strong>${notification.groupName} - ${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/workmaterials/${notification.subjectUuid}`;

      break;
    case NotificationReason.GroupMaterialUpdated:
      content = `${t('notifications.materialUpdated')} <strong>${notification.groupName} - ${notification.subjectName}</strong>`;
      targetUrl = `/groups/${notification.groupUuid}/workmaterials/${notification.subjectUuid}`;
      break;

    case NotificationReason.GroupMaterialCreated:
      content = `${t('notifications.newMaterial')} <strong>${notification.groupName} - ${notification.subjectName}</strong>`;
      targetUrl = `/groups/${notification.groupUuid}/workmaterials/${notification.subjectUuid}`;
      break;

    case NotificationReason.SubmissionNew:
      if (
        notification.subjectName !== undefined &&
        notification.assignmentUuid !== undefined &&
        notification.subjectUuid !== undefined &&
        notification.groupUuid !== undefined
      ) {
        content = `${t('notifications.newSubmission')} <strong>${notification.subjectName}</strong>`;
        targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      }

      break;

    case NotificationReason.CourseMaterialDeleted:
      content = `${t('notifications.materialDeleted')}: <strong>${notification.groupName} - ${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}`;
      break;

    case NotificationReason.GroupMaterialDeleted:
      content = `${t('notifications.materialDeleted')}: <strong>${notification.groupName} - ${notification.subjectName}</strong>`;
      targetUrl = `/groups/${notification.groupUuid}`;
      break;

    default:
      break;
  }

  return { content, targetUrl };
}
