import { getClaims, getTokens } from './authStore';
import { NotificationType } from './matrixClient';

export const matrixTokenName = 'matrixToken';

export type TestForSufficientBBBSlotsInput = {
  id: string;
  maxAttendees: number;
  start: string;
  end: string;
  until?: string;
  organization: string;
};

export interface BackendApiInterface {
  triggerPimImport: () => Promise<void>;
  findConference: (conference: string) => Promise<Response>;
  startConference: (conference: string) => Promise<Response>;
  endConference: (conference: string) => Promise<Response>;
  joinConference: (conference: string, params: JoinParams) => Promise<Response>;
  getMatrixToken: (force?: boolean) => Promise<MatrixTokenType | null>;
  clearMatrixTokens: () => Promise<void>;
  createMatrixDm: (uuid: string) => Promise<Response>;
  redactMatrixEvent: (roomId: string, eventId: string) => Promise<Response>;
  notify: (message: Partial<NotificationType>) => Promise<void>;

  fetch(url: string, options?: RequestInit): Promise<Response>;

  testForSufficientBBBSlots(input: TestForSufficientBBBSlotsInput): Promise<
    {
      [uuid: string]: number;
    }[]
  >;
}

export type MatrixTokenType = {
  access_token: string;
  user_id: string;
  home_server: string;
  expires_at: number;
};

export type JoinParams = {
  displayName: string;
  email?: string;
  profileUuid?: string;
  moderator?: boolean;
};

export const restApiPath = () => import.meta.env.VITE_APP_BACKEND_URI + import.meta.env.VITE_APP_BACKEND_REST_PATH;

export const backendApi: BackendApiInterface = {
  async fetch(url: string, options?: RequestInit): Promise<Response> {
    const tokens = await getTokens();
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      Authorization: tokens ? `Bearer ${tokens.access_token}` : '',
    };
    const opts: RequestInit = {
      method: 'GET',
      headers,
    };
    return fetch(restApiPath() + url, { ...options, ...opts });
  },

  triggerPimImport: async () => {
    const tokens = await getTokens();
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      Authorization: tokens ? `Bearer ${tokens.access_token}` : '',
    };
    const opts: RequestInit = {
      method: 'POST',
      headers,
    };
    await fetch(restApiPath() + `/setup/sync-pim`, opts);
  },
  findConference: async (conference: string) => {
    const tokens = await getTokens();
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      Authorization: tokens ? `Bearer ${tokens.access_token}` : '',
    };
    const opts: RequestInit = {
      headers,
    };
    return fetch(restApiPath() + `/calendar/meeting/${conference}`, opts);
  },
  async endConference(conference: string): Promise<Response> {
    return this.fetch(restApiPath() + `/calendar/stop/${conference}`, { method: 'POST' });
  },
  async joinConference(conference: string, params: JoinParams): Promise<Response> {
    return fetch(restApiPath() + `/calendar/join/${conference}`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  async startConference(conference: string): Promise<Response> {
    return fetch(restApiPath() + `/calendar/start/${conference}`, { method: 'POST' });
  },

  async testForSufficientBBBSlots(input: {
    id: string;
    maxAttendees: number;
    start: string;
    end: string;
    organization: string;
  }): Promise<
    {
      [uuid: string]: number;
    }[]
  > {
    const tokens = await getTokens();
    const result = await fetch(restApiPath() + `/bbb/check-limits`, {
      method: 'POST',
      body: JSON.stringify([input]),
      headers: {
        Authorization: `Bearer ${tokens?.access_token ?? ''}`,
        'Content-Type': 'application/json',
      },
    });

    return await result.json();
  },

  clearMatrixTokens: async (): Promise<void> => {
    sessionStorage.clear();
    return;
  },

  getMatrixToken: async (force): Promise<MatrixTokenType | null> => {
    const claims = await getClaims();
    const prefix = claims?.sub ?? '';

    // force token renewal
    if (force) sessionStorage.removeItem(`${prefix}_${matrixTokenName}`);

    let tokenString = sessionStorage.getItem(`${prefix}_${matrixTokenName}`);

    if (!tokenString) {
      const tokens = await getTokens();
      const response = await fetch(`${restApiPath()}/matrix/token`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${tokens?.access_token}` },
      });
      const token = await response.json();
      if (token && token.access_token && token.user_id && token.home_server) {
        tokenString = JSON.stringify(token);
        sessionStorage.setItem(`${prefix}_${matrixTokenName}`, tokenString);
      }
    }
    return tokenString ? JSON.parse(tokenString) : null;
  },

  createMatrixDm: async (uuid) => {
    const tokens = await getTokens();

    return fetch(`${restApiPath()}/matrix/dm/${uuid}`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${tokens?.access_token}` },
    });
  },

  redactMatrixEvent: async (roomId: string, eventId: string) => {
    const tokens = await getTokens();

    return await fetch(`${restApiPath()}/matrix/action`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${tokens?.access_token}` },
      body: JSON.stringify({ action: 'redact', uuid: roomId, eventId: eventId }),
    });
  },

  notify: async (message: Partial<NotificationType>): Promise<void> => {
    const body = JSON.stringify(message);
    const tokens = await getTokens();

    void fetch(`${restApiPath()}/notify`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${tokens?.access_token}`, 'content-type': 'application/json' },
      body: body,
    });
  },
};
