import { SecureStorage } from '@aparajita/capacitor-secure-storage';
import { AsyncStorage } from 'oidc-client-ts';

export class CapacitorStorage implements AsyncStorage {
  readonly length = SecureStorage.keys().then((keys) => keys.length);

  async clear() {
    await SecureStorage.clear();
  }

  async getItem(key: string) {
    const item = await SecureStorage.get(key);
    if (typeof item === 'string') {
      return item;
    }
    return null;
  }

  async key(index: number) {
    const keys = await SecureStorage.keys();
    return keys.length >= index ? keys[index] : null;
  }

  async removeItem(key: string) {
    await SecureStorage.remove(key);
  }

  async setItem(key: string, value: string) {
    await SecureStorage.set(key, value);
  }
}
