import { useAuthClaims } from '../../useAuthClaims';
import {
  useBpCalendarEventsQuery,
  useBpCollaboratingOrganizationsQuery,
  useBpSubmissionsQuery,
  useGroupAsGroupsQuery,
} from '../../../client/bp-graphql-client-defs';
import { isNotificationType, NotificationReason, NotificationType } from '../../../utils/matrixClient';
import { useEffect, useState } from 'react';
import { mapNotification } from '../../../components/Header/components/Notifications/mapNotification';
import { useMemoizedCacheTag } from '../../useMemoizedCacheTag';
import { useMatrixAvailable } from '../useMatrixAvailable';
import { useRefreshOnEvent } from '../useRefreshOnEvent';
import { useMatrixClient } from '../useMatrixClient';
import { MatrixEvent } from 'matrix-js-sdk';
import { BaseDataType, useNotificationBaseData } from './useNotificationBaseData';

export enum BpRoomType {
  DM = 'dm',
  GROUP = 'group',
  COURSE = 'course',
  ORGANIZATION = 'organization',
  SUBMISSION = 'submission',
}

export const useNotifications = () => {
  const { pimAuthClaims } = useAuthClaims();
  const online = useMatrixAvailable();
  const refresh = useRefreshOnEvent();

  const matrixClient = useMatrixClient();

  const rooms = matrixClient?.getVisibleRooms().filter((room) => {
    const parts = room?.name.split(':')[0].split('_');
    const roomType: BpRoomType = parts[0] as BpRoomType;
    return roomType !== BpRoomType.DM;
  });

  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  const profileUuid = pimAuthClaims.getProfile().uuid;
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const otherProfilesUuids = pimAuthClaims.getOtherProfiles().map((p) => p.uuid);

  const groupContext = useMemoizedCacheTag('GROUP');
  const submissionContext = useMemoizedCacheTag('SUBMISSION');

  const [{ data: profileSubmissions }, refetchSubmissions] = useBpSubmissionsQuery({
    variables: {
      where: {
        ownerConnection: {
          node: {
            uuid_IN: [profileUuid, ...otherProfilesUuids],
          },
        },
      },
    },
    context: submissionContext,
  });

  const [{ data: groupsData }] = useGroupAsGroupsQuery({
    variables: {
      where: {
        OR: [
          { editors_SOME: { uuid_IN: [profileUuid, ...otherProfilesUuids] } },
          { viewers_SOME: { uuid_IN: [profileUuid, ...otherProfilesUuids] } },
        ],
      },
    },
    context: groupContext,
  });
  const [{ data: calendarEventsData }] = useBpCalendarEventsQuery({
    variables: {
      where: {
        attendees_SOME: { uuid: profileUuid },
      },
    },
    context: groupContext,
  });

  const [{ data: organizationsData }] = useBpCollaboratingOrganizationsQuery({
    variables: { uuid: pimAuthClaims.getOrganizationUuid() },
  });

  const { createBaseData } = useNotificationBaseData();

  function dontShow(matrixEvent: MatrixEvent, type: string, latestRoomJoinEvent: number) {
    return (
      // hide legacy messages
      !Object.values(NotificationReason).includes(type as NotificationReason) ||
      // hide certain messages from the past
      (latestRoomJoinEvent > matrixEvent.getTs() &&
        type !== NotificationReason.GroupAnnouncement &&
        type !== NotificationReason.CourseAnnouncement &&
        type !== NotificationReason.OrganizationAnnouncement &&
        type !== NotificationReason.GroupMaterialCreated &&
        type !== NotificationReason.CourseMaterialCreated)
    );
  }

  useEffect(() => {
    const notifications: NotificationType[] = [];

    const latestJoinEventByRoom: Record<string, number> = {};

    const _updateLatestJoinEventTS = (matrixEvent: MatrixEvent) => {
      const isJoinEvent = matrixEvent.getContent().membership === 'join';
      const eventTs = matrixEvent.getTs();
      const roomId = matrixEvent.getRoomId();

      if (isJoinEvent && roomId) {
        if (roomId in latestJoinEventByRoom) {
          // we have apparently already seen a 'join' event for this user in this room; take the latest
          const oldEventTs = latestJoinEventByRoom[roomId];

          if (eventTs > oldEventTs) {
            latestJoinEventByRoom[roomId] = eventTs;
          }
        } else {
          latestJoinEventByRoom[roomId] = eventTs;
        }
      }
    };

    function _handleEventCreated(baseData: BaseDataType | undefined, payload: NotificationType, groupUuid: string) {
      if (
        [
          NotificationReason.RecurringGroupEventCreated,
          NotificationReason.GroupEventCreated,
          NotificationReason.RecurringCourseEventCreated,
          NotificationReason.CourseEventCreated,
        ].includes(payload.type)
      ) {
        payload.title = groupsData?.groups.find((g) => g.uuid === groupUuid)?.name ?? 'No Name';
      }

      if (payload.type === NotificationReason.OrganizationEventCreated) {
        payload.title = baseData?.subjectName;
      }

      return payload;
    }

    rooms?.forEach((room) => {
      const parts = room?.name.split(':')[0].split('_');
      const roomType: BpRoomType = parts[0] as BpRoomType;
      const groupUuid: string | undefined = parts[2];
      const typeUuid = parts[1];
      const events = room?.getLiveTimeline().getEvents();

      const baseData = createBaseData(roomType, typeUuid, groupUuid);

      for (const matrixEvent of events) {
        _updateLatestJoinEventTS(matrixEvent);

        const eventBody = matrixEvent.getContent().body;
        if (!eventBody) continue;

        const roomId = matrixEvent.getRoomId();

        let payload: NotificationType | null = null;
        try {
          payload = JSON.parse(eventBody);
        } catch {
          // probably is a text message
          // the only place we use this are submissions
          if (
            eventBody &&
            // a teacher has no submissions, and only he / her should see this message
            !profileSubmissions?.submissions.find((s) => s.owner.uuid === pimAuthClaims.getProfile().uuid)
          ) {
            refetchSubmissions({ where: { uuid: groupUuid } });

            payload = {
              type: NotificationReason.SubmissionMessage,
              date: matrixEvent.getDate() ?? new Date(),
              eventId: matrixEvent.getId(),
              roomId,
              content: eventBody,
            };
          }
        }

        if (isNotificationType(payload)) {
          // can be removed when typing ist consistent
          const type = payload?.type?.charAt(0).toLowerCase() + payload?.type?.slice(1);

          if (roomId && dontShow(matrixEvent, type, latestJoinEventByRoom[roomId])) continue;

          const data: NotificationType = {
            date: matrixEvent.getDate() ?? new Date(),
            type: payload?.type,
            eventId: matrixEvent.getId(),
            roomId: matrixEvent.getRoomId(),
          };

          if (
            [
              NotificationReason.OrganizationEventCreated,
              NotificationReason.CourseEventCreated,
              NotificationReason.GroupEventCreated,
              NotificationReason.RecurringEventCreated,
              NotificationReason.RecurringGroupEventCreated,
              NotificationReason.RecurringCourseEventCreated,
            ].includes(type as NotificationReason)
          ) {
            payload = { ...payload, ..._handleEventCreated(baseData, payload, groupUuid) };
          }

          // TODO: handle organization- and 1v1-meetings
          if (
            [
              NotificationReason.GroupEventStarted,
              NotificationReason.CourseEventStarted,
              // NotificationReason.OrganizationEventStarted,
              NotificationReason.GroupEventEnded,
              NotificationReason.CourseEventEnded,
              // NotificationReason.OrganizationEventEnded,
            ].includes(type as NotificationReason)
          ) {
            const event = calendarEventsData?.calendarEvents.find(
              (evt) => evt.uuid === (payload as NotificationType).subjectUuid,
            );
            payload.groupUuid =
              event?.holderConnection.edges
                ?.filter((edge) => edge.node.__typename === 'Group')
                .map((edge) => edge.node.uuid)
                .shift() ?? '';
          }

          const { content, targetUrl } = mapNotification({ ...baseData, ...payload, organizationUuid });
          if (content === '') continue;

          if (content) {
            data.content = content;
            data.targetUrl = targetUrl;
            notifications.push(data);
          }
        }
      }
    });
    const sorted = notifications.sort((a, b) => b.date.getTime() - a.date.getTime());
    setNotifications(sorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    online,
    refresh,
    organizationUuid,
    groupsData?.groups,
    profileSubmissions?.submissions,
    organizationsData?.collaboratingOrganizations,
  ]);

  return notifications;
};
