import {
  CHILD_PROFILES_CLAIM,
  ChildProfilesClaim,
  ORGANIZATION_CLAIM,
  OrganizationClaim,
  OTHER_PROFILES_CLAIM,
  OtherProfilesClaim,
  PROFILE_CLAIM,
  ProfileClaim,
  ROLES_CLAIM,
  RolesClaim,
} from '@bp/pim-auth-constants';
import { bpUserManager } from '../BpAuthProvider';
import dayjs from 'dayjs';
import { User } from 'oidc-client-ts';

type ExtendedUser = Omit<User, 'profile'> & { profile: User['profile'] & UserInfoResponse };

const getAuthData = async (): Promise<ExtendedUser | null> => {
  const user = (await bpUserManager.getUserManager().getUser()) as ExtendedUser | null;
  return user;
};

const setData = async (data: ExtendedUser | null) => {
  await bpUserManager.getUserManager().storeUser(data);
};

const removeData = async () => {
  await bpUserManager.getUserManager().removeUser();
};

export const clearTokens = async () => {
  await removeData();
};

export const getClaims = async (): Promise<UserInfoResponse | null> => {
  const data = await getAuthData();
  if (data) {
    return data.profile;
  }
  return null;
};

export const getTokens = async (): Promise<{
  access_token?: string;
  refresh_token?: string;
  expires_at?: number;
  id_token?: string;
} | null> => {
  const tokenData = await getAuthData();
  if (tokenData) {
    const accessToken = tokenData.access_token;
    const refreshToken = tokenData.refresh_token;
    let expiresAt = tokenData.expires_at;
    const idToken = tokenData.id_token;

    if (expiresAt && expiresAt <= dayjs().unix()) {
      expiresAt = dayjs().add(expiresAt).unix();
      await setData({ ...tokenData, expires_at: expiresAt });
    }

    if (accessToken) {
      return { access_token: accessToken, refresh_token: refreshToken, expires_at: expiresAt, id_token: idToken };
    }
  }
  return null;
};

export type UserInfoResponse = {
  sub: string;
  PROFILE_CLAIM: ProfileClaim;
  OTHER_PROFILES_CLAIM: OtherProfilesClaim;
  CHILD_PROFILES_CLAIM: ChildProfilesClaim;
  ORGANIZATION_CLAIM: OrganizationClaim;
  ROLES_CLAIM: RolesClaim;
};

export async function updateClaims(callback?: (userInfo: UserInfoResponse | undefined) => void) {
  let userInfo: UserInfoResponse | undefined = undefined;
  const tokens = await getTokens();

  if (tokens?.access_token !== null) {
    userInfo = await fetch(import.meta.env.VITE_APP_AUTH_SERVER_URI + '/me', {
      headers: { Authorization: 'Bearer ' + tokens?.access_token },
    })
      .then((r) => r.json() as unknown as UserInfoResponse)
      .catch(() => undefined);

    if (userInfo) {
      const newUserInfo = await getAuthData();
      if (newUserInfo?.profile.sub === userInfo.sub) {
        newUserInfo.profile[PROFILE_CLAIM] = userInfo[PROFILE_CLAIM as keyof UserInfoResponse];
        newUserInfo.profile[OTHER_PROFILES_CLAIM] = userInfo[OTHER_PROFILES_CLAIM as keyof UserInfoResponse];
        newUserInfo.profile[CHILD_PROFILES_CLAIM] = userInfo[CHILD_PROFILES_CLAIM as keyof UserInfoResponse];
        newUserInfo.profile[ORGANIZATION_CLAIM] = userInfo[ORGANIZATION_CLAIM as keyof UserInfoResponse];
        newUserInfo.profile[ROLES_CLAIM] = userInfo[ROLES_CLAIM as keyof UserInfoResponse];
      }

      await setData(newUserInfo);
    } else {
      removeData();
    }
  }

  callback && callback(userInfo);
  return userInfo;
}
