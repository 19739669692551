import { NotificationType } from '../../../utils/matrixClient';
import { BpRoomType } from './useNotifications';
import { useMemoizedCacheTag } from '../../useMemoizedCacheTag';
import {
  useBpCollaboratingOrganizationsQuery,
  useBpSubmissionsQuery,
  useGroupAsGroupsQuery,
} from '../../../client/bp-graphql-client-defs';
import { useAuthClaims } from '../../useAuthClaims';

export type BaseDataType = Pick<
  NotificationType,
  'subjectUuid' | 'subjectName' | 'groupUuid' | 'isCommunity' | 'assignmentUuid' | 'groupName'
>;

export function useNotificationBaseData() {
  const { pimAuthClaims } = useAuthClaims();
  const profileUuid = pimAuthClaims.getProfile().uuid;
  const otherProfilesUuids = pimAuthClaims.getOtherProfiles().map((p) => p.uuid);

  const groupContext = useMemoizedCacheTag('GROUP');
  const submissionContext = useMemoizedCacheTag('SUBMISSION');

  const [{ data: profileSubmissions }] = useBpSubmissionsQuery({
    variables: {
      where: {
        OR: [
          {
            ownerConnection: {
              node: {
                uuid_IN: [profileUuid, ...otherProfilesUuids],
              },
            },
          },

          // the user is a editor, aka Teacher,  in the submission's course
          {
            assignment_ALL: {
              holder: {
                TeachingUnit: {
                  group: {
                    editors_SOME: { uuid: profileUuid },
                  },
                },
              },
            },
          },
        ],
      },
    },
    context: submissionContext,
  });

  const [{ data: groupsData }] = useGroupAsGroupsQuery({
    variables: {
      where: {
        OR: [
          { editors_SOME: { uuid_IN: [profileUuid, ...otherProfilesUuids] } },
          { viewers_SOME: { uuid_IN: [profileUuid, ...otherProfilesUuids] } },
        ],
      },
    },
    context: groupContext,
  });

  const [{ data: organizationsData }] = useBpCollaboratingOrganizationsQuery({
    variables: { uuid: pimAuthClaims.getOrganizationUuid() },
  });

  const createBaseData = (roomType: BpRoomType, typeUuid: string, groupUuid = '') => {
    const group = groupsData?.groups.find((g) => g?.uuid === groupUuid);

    const baseData: BaseDataType = {
      subjectUuid: '',
      subjectName: '',
    };

    switch (roomType) {
      case BpRoomType.GROUP: {
        if (!group?.uuid) return;
        if (group) {
          baseData.subjectName = group.name;
          baseData.groupUuid = group.uuid;
          baseData.groupName = group.name;
          baseData.isCommunity = group.isCommunity ?? false;
        }

        break;
      }

      case BpRoomType.SUBMISSION: {
        const s = profileSubmissions?.submissions.find((s) => s?.uuid === typeUuid);
        if (!s?.assignment[0]) return;

        baseData.subjectName = s?.assignment[0]?.title ?? '';
        baseData.subjectUuid = typeUuid;
        baseData.assignmentUuid = s?.assignment[0]?.uuid;
        baseData.groupUuid = s?.assignment[0]?.holder.group.uuid;

        break;
      }

      case BpRoomType.ORGANIZATION: {
        const collabOrga = organizationsData?.collaboratingOrganizations.find((o) => o.uuid === typeUuid);
        if (collabOrga) {
          baseData.subjectUuid = typeUuid;
          baseData.subjectName = collabOrga.name ?? '';
        } else {
          // this organization
          baseData.subjectUuid = pimAuthClaims.getOrganizationUuid();
          baseData.subjectName = pimAuthClaims.getOrganization().name;
        }
        break;
      }
    }
    return baseData;
  };

  return { createBaseData };
}
